import { get, post } from './index.js'

/**
 * 分页获取设备列表
 * @param data 分页条件
 * @returns {Promise<any>} 分页设备列表
 * @constructor
 */
export const ReqEquipmentGetListByPage = (data) => {
  return get('/equipment/list', data).then(res => res)
}

// 根据组织查询设备
export const listEquipmentByOrg = (id) => {
  return get(`/equipment/org/list/${id}`).then(res => res)
}

/**
 * 获取设备详情
 * @param params 设备Id
 * @returns {Promise<any>} 设备详情
 * @constructor
 */
export const ReqEquipmentGetDetail = (params) => {
  return get('/equipment/getDetail', params).then(res => res)
}

/**
 * 保存设备信息
 * @param data 设备信息
 * @returns {Promise<any>} 设备Id
 * @constructor
 */
export const ReqEquipmentSaveEquipment = (data) => {
  return post('/equipment/saveEquipment', data).then(res => res)
}

/**
 * 获取账户列表及密码 任佳 2024年8月6日 14:43:08
 * @param orgId
 * @returns {Promise<any>}
 * @constructor
 */
export const getAccountList = (orgId) => {
  return get(`/equipment/org/equipment/list/${orgId}`).then(res => res)
}
