<template>
    <span :class="'pdt-device-match-status-'+value">
        {{FmtDeviceMatchStatus(value)}}
    </span>
</template>

<script>
    import {FmtEnum} from "../../util/enumUtil";

    export default {
        name: "DeviceMatchStatus",
        props: {
            value: {
                type: String,
                default: 'INITIAL'
            }
        },
        methods: {
            FmtDeviceMatchStatus() {
                const dms = this.$store.state.enums.DeviceMatchStatus;
                return FmtEnum(dms, this.value);
            }
        }
    }
</script>

<style scoped>

</style>