<template>
    <span :class="'pdt-agent-online-status-'+value">
        {{FmtAgentOnlineStatus(value)}}
    </span>
</template>

<script>
    import {FmtEnum} from "../../util/enumUtil";

    export default {
        name: "AgentOnlineStatus",
        props: {
            value: {
                type: String,
                default: 'INITIAL'
            }
        },
        methods: {
            FmtAgentOnlineStatus() {
                const aos = this.$store.state.enums.AgentOnlineStatus;
                return FmtEnum(aos, this.value);
            }
        }
    }
</script>

<style scoped>

</style>